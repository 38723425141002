var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns fixed-page"},[_c('div',{staticClass:"column main-column"},[_c('div',{staticClass:"person page"},[_c('div',{staticClass:"flexrow page-header"},[(_vm.person)?_c('div',{staticClass:"flexrow-item"},[_c('people-avatar',{attrs:{"person":_vm.person,"size":80,"font-size":30,"is-text":false}})],1):_vm._e(),_c('div',{staticClass:"flexrow-item"},[_c('page-title',{attrs:{"text":_vm.person ? _vm.person.name : ''}})],1)]),_c('div',{staticClass:"task-tabs tabs"},[(_vm.person)?_c('ul',[_c('li',{class:{'is-active': _vm.isActiveTab('todos')}},[_c('router-link',{attrs:{"to":{
              name: 'person',
              params: {
                person_id: _vm.person.id
              }
            }}},[_vm._v(" "+_vm._s(_vm.$t('tasks.current'))+" ")])],1),_c('li',{class:{'is-active': _vm.isActiveTab('done')},on:{"click":function($event){return _vm.selectTab('done')}}},[_c('router-link',{attrs:{"to":{
              name: 'person-tab',
              params: {
                tab: 'done',
                person_id: _vm.person.id
              }
            }}},[_vm._v(" "+_vm._s(_vm.$t('tasks.done'))+" ("+_vm._s(_vm.displayedPersonDoneTasks.length)+") ")])],1),(_vm.isCurrentUserManager)?_c('li',{class:{'is-active': _vm.isActiveTab('timesheets')},on:{"click":function($event){return _vm.selectTab('timesheets')}}},[_c('router-link',{attrs:{"to":{
              name: 'person-tab',
              params: {
                tab: 'timesheets',
                person_id: _vm.person.id
              }
            }}},[_vm._v(" "+_vm._s(_vm.$t('timesheets.title'))+" ")])],1):_vm._e()]):_vm._e()]),_c('div',{staticClass:"flexrow"},[(!_vm.isActiveTab('done'))?_c('search-field',{ref:"person-tasks-search-field",class:{
            'search-field': true,
            'flexrow-item': true
          },attrs:{"can-save":true},on:{"change":_vm.onSearchChange,"save":_vm.saveSearchQuery}}):_vm._e(),_c('span',{staticClass:"filler"}),_c('combobox',{staticClass:"flexrow-item",attrs:{"label":_vm.$t('main.sorted_by'),"options":_vm.sortOptions,"locale-key-prefix":"tasks.fields."},model:{value:(_vm.currentSort),callback:function ($$v) {_vm.currentSort=$$v},expression:"currentSort"}})],1),(_vm.isActiveTab('todos') || _vm.isActiveTab('timesheets'))?_c('div',{staticClass:"query-list"},[_c('search-query-list',{attrs:{"queries":_vm.personTaskSearchQueries},on:{"change-search":_vm.changeSearch,"remove-search":_vm.removeSearchQuery}})],1):_vm._e(),(_vm.isActiveTab('todos'))?_c('todos-list',{ref:"task-list",attrs:{"tasks":_vm.sortedTasks,"is-loading":_vm.isTasksLoading,"is-error":_vm.isTasksLoadingError,"selection-grid":_vm.personTaskSelectionGrid},on:{"scroll":_vm.setPersonTasksScrollPosition}}):_vm._e(),(_vm.isActiveTab('done'))?_c('todos-list',{ref:"done-list",attrs:{"tasks":_vm.displayedPersonDoneTasks,"is-loading":_vm.isTasksLoading,"is-error":_vm.isTasksLoadingError,"done":true,"selectionGrid":_vm.personTaskSelectionGrid}}):_vm._e(),(_vm.isActiveTab('timesheets'))?_c('timesheet-list',{attrs:{"tasks":_vm.loggablePersonTasks,"done-tasks":_vm.loggableDoneTasks,"is-loading":_vm.isTasksLoading,"is-error":_vm.isTasksLoadingError,"time-spent-map":_vm.personTimeSpentMap,"time-spent-total":_vm.personTimeSpentTotal,"hide-done":_vm.personTasksSearchText.length > 0},on:{"date-changed":_vm.onDateChanged,"time-spent-change":_vm.onTimeSpentChange,"set-day-off":_vm.onSetDayOff,"unset-day-off":_vm.onUnsetDayOff}}):_vm._e()],1)]),(_vm.nbSelectedTasks === 1)?_c('div',{staticClass:"column side-column"},[_c('task-info',{attrs:{"task":_vm.selectedTasks.values().next().value}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }